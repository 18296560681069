import { motion } from 'framer-motion'
import { RichTextBlock } from 'prismic-reactjs'
import styled, { css } from 'styled-components'

import { CardAspectStyles, CardStyles } from '../../src/components/Card'
import ImageCard from '../../src/components/Card/ImageCard'
import LottieCard from '../../src/components/Card/LottieCard'
import RichTextCard from '../../src/components/Card/RichTextCard'
import VideoCard from '../../src/components/Card/VideoCard'
import { FullScreenCardGridStyles } from '../../src/layout/grid/Grid'

import { useCardViewportScaleAnimation } from '../../src/utils/hooks/use-card-viewport-scale-animation'
import { AutoSpace, media } from '../../src/utils/style-utils'
import { CardSlice } from '../../prismicio-types'

enum BackgroundColors {
	'Neon' = 'var(--neon)',
	'Blue' = 'var(--deep-blue)',
	'Baby Blue' = 'var(--baby-blue-400)',
}

const CardsRow = ({ slice }: { slice: CardSlice }) => {
	const reverse = slice.primary.reverse
	const cardProps1 = useCardViewportScaleAnimation()
	const cardProps2 = useCardViewportScaleAnimation()

	return (
		<Grid>
			<CardRow>
				<CardWrapper
					{...cardProps1}
					order={reverse ? 2 : 1}
					backgroundColor={BackgroundColors[slice.primary.card_color ?? 'Neon']}
				>
					<RichTextCard slice={slice} />
				</CardWrapper>
				<CardWrapper
					{...cardProps2}
					backgroundColor={
						slice.variation == 'lottie' ? BackgroundColors[slice.primary.lottie_background_color ?? 'Neon'] : undefined
					}
					order={reverse ? 1 : 2}
				>
					<CardVariations slice={slice} />
				</CardWrapper>
			</CardRow>
		</Grid>
	)
}

export default CardsRow

const CardVariations = ({ slice }) => {
	switch (slice.variation) {
		case 'default':
			return <ImageCard image={slice.primary.image} />
			break

		case 'video':
			return (
				<VideoCard
					animateOnHover={slice.primary.animate_on_hover}
					media={slice.primary.media}
				/>
			)
			break

		case 'lottie':
			return (
				<LottieCard
					media={slice.primary.media}
					animateOnHover={slice.primary.animate_on_hover}
				/>
			)
			break

		default:
			return null
			break
	}
}

const Grid = styled.div`
	${FullScreenCardGridStyles}
`

const CardRow = styled.div`
	grid-column: 1 / -1;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: ${AutoSpace.desktop}px;

	${media.tablet`
		grid-template-columns: 1fr;
		gap: ${AutoSpace.tablet}px;
	`};
`

const CardWrapper = styled.div<{ backgroundColor?: BackgroundColors; order: number }>`
	${CardStyles}
	${CardAspectStyles}


	order: ${(p) => p.order};
	${(p) => {
		return (
			p.backgroundColor === BackgroundColors['Blue'] &&
			css`
				color: var(--off-white);
			`
		)
	}}

	transition: all .2s ease-in-out;

	:hover {
		transform: scale(0.98);
	}
`
